<template>
  <div id="cdp-general">
    <p class="subTitle mb-5 mt-5">{{ $t('cdp.info') }}</p>
    <address-picker ref="addressPicker" v-model="oCDP" :loading="loading.get">
      <template #atBegin>
        <Field
          class="name"
          v-model="oCDP.name"
          :label="$t('run.name')"
          :avoidSpecialChars="false"
          required
        />
      </template>
      <template #field>
        <phone-picker
          class="phone-picker"
          v-model="oCDP.phone"
          :label="$t('contact.phone')"
          :loading="loading.get"
        />
      </template>
    </address-picker>
    <div class="flex f-jce mt-3 mb-3">
      <b-button
        type="is-primary"
        :label="$t('button.save')"
        @click="onSave"
        :loading="loading.save"
      />
    </div>
  </div>
</template>

<script>
import { AddressPicker, Field, PhonePicker } from '@/components';
import { toast } from '@/utils/dialog';

export default {
  components: {
    AddressPicker,
    Field,
    PhonePicker
  },
  mounted() {
    this.$store.dispatch('setBreadcumbs', this.breadcumbs);
  },
  created() {
    this.unsubscribe = this.$store.subscribe(({ type }, { map }) => {
      if (type == 'map/response') {
        console.warn(map?.response?.data?.latLng);
        if (map?.response?.action == 'position') this.setPosition(map?.response?.data?.latLng);
      }
    });
  },
  destroyed() {
    this.unsubscribe();
  },
  computed: {
    breadcumbs() {
      return ['menu.dat', this.cdp.id ? 'cdp.edit' : 'cdp.new'];
    }
  },
  data() {
    const oCDP = {
      ...this.cdp
    };
    return {
      loading: { get: false, save: false },
      oCDP
    };
  },
  methods: {
    async onSave() {
      this.loading.save = true;
      if (this.validate()) {
        try {
          const { id } = this.cdp;
          const pData = this.prepareData();
          const method = id ? 'put' : 'post';
          const url = `/cdp${id ? `/${id}` : ''}`;
          const { data } = await this.Api[method](url, pData);
          this.cdp.id = data.id;
          toast('success', this.$t('messages.saved'), 5000);
          this.$emit('change', this.cdp);
          this.$router.replace({
            path: `/data/cdps/${this.cdp.id}`
          });
        } catch (error) {
          console.error(error);
        }
      }
      this.loading.save = false;
    },
    prepareData() {
      const { oCDP } = this;
      return {
        name: oCDP.name,
        address: oCDP.address,
        address_1: oCDP.address_1,
        formatted_address: oCDP.formatted_address || '',
        state: oCDP.state,
        city: oCDP.city,
        zipcode: oCDP.zipcode,
        latitude: oCDP.latitude || '',
        longitude: oCDP.longitude || '',
        phone: oCDP.phone
      };
    },
    onPickPosition(address) {
      this.showMap(address, true);
    },
    onShowPosition(address) {
      this.showMap(address, false);
    },
    prepareForMaps({ address, state, city, latitude, longitude, zipcode }) {
      const comp = [];
      if (address) comp.push(address);
      if (city) comp.push(city);
      if (state) comp.push(state);
      if (zipcode) comp.push(`zc ${zipcode}`);
      return {
        address: comp.join(', '),
        latLng: { lat: latitude, lng: longitude }
      };
    },
    restoreMap() {
      this.$store.commit('map/update', { action: 'restore' });
    },
    setPosition({ lat, lng } = {}) {
      this.oCDP.latitude = lat || null;
      this.oCDP.longitude = lng || null;
    },
    showMap(oAdress, pick) {
      const data = this.prepareForMaps(oAdress);
      this.$store.dispatch('map/updateMapWindow', {
        action: pick ? 'getPosition' : 'showPosition',
        data
      });
    },
    updateMapBreadcumbs(clear = false) {
      this.$store.commit('map/breadcumbs', clear ? null : this.breadcumbs);
    },
    validate() {
      const addressValidator = this.$refs.addressPicker.validate();
      return addressValidator;
    }
  },
  watch: {
    cdp(cdp) {
      this.oCDP = cdp;
    },
    '$route.path'(value) {
      if (value === '/data/cdps/add') {
        this.$store.dispatch('setBreadcumbs', ['menu.dat', 'cdp.new']);
      } else {
        this.$store.dispatch('setBreadcumbs', ['menu.dat', 'cdp.edit']);
      }
    }
  },
  props: {
    cdp: {
      type: Object,
      required: true
    }
  }
};
</script>

<style lang="sass" scoped>
#cdp-general
  .subTitle
    font-size: $f-xl
    font-weight: 700
  .name
    width: calc(33.33% - .75rem)
  ::v-deep
    .address-picker
      .ap-address,
      .ap-address1
        width: calc(33.33% - .75rem)
      .ap-address1
        margin-right: 0px
      .ap-city,
      .ap-state
        width: calc(25% - .75rem)
      .ap-zipcode
        width: calc(20% - .75rem)
    .phone-picker
      width: calc(28% - 1.45rem)
</style>
