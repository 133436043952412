<template>
  <section>
    <Card :loading="loading.get">
      <template #header>
        <StepCardHeader v-model="current" :steps="steps" returnRoute="/data/cdps" />
      </template>
      <General-step v-if="current == 1" :cdp="oCDP" @change="onChange" />
    </Card>
  </section>
</template>

<script>
import Card from '@/components/Card.vue';
import GeneralStep from './GeneralStep.vue';
import StepCardHeader from '@/components/StepCardHeader';
export default {
  components: {
    Card,
    GeneralStep,
    StepCardHeader
  },
  async created() {
    if (this.paramsCDPId) await this.getCDP();
    if (!this.oCDP.name) {
      this.$router.push('/data/cdps/add');
    }
  },
  data() {
    const paramsCDPId = Number(this.$route.params.cdpId || 0);
    return {
      paramsCDPId,
      loading: { get: false, save: false },
      oCDP: { id: paramsCDPId },
      steps: [{ name: 'global.general' }],
      current: this.$route.meta?.current || 1
    };
  },
  computed: {},
  methods: {
    async getCDP() {
      this.loading.get = true;
      try {
        const { data } = await this.Api.get(`cdp/${this.paramsCDPId}`);
        this.oCDP = data;
      } catch (error) {
        console.error(error);
      }
      this.loading.get = false;
    },
    onChangeStep(step = 1) {
      const routes = [''];
      if (this.oCDP.id) {
        this.$router.replace({
          path: `/data/cdps/${this.oCDP.id}${routes[step - 1]}`
        });

        this.current = step;
      }
    },
    onChange(cdp) {
      this.oCDP = cdp;
      this.paramsCDPId = cdp.id;
    },
    goBack() {
      this.$router.go(-1);
    }
  }
};
</script>
<style lang="sass" scoped>
.card
  margin: 0
  min-height: calc(100% - 90px)
  ::v-deep
    .card-header
      padding: 0
      justify-content: flex-end
</style>
